import { useStaticQuery, graphql } from "gatsby";

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        posts(orderBy: { publishDate: desc }) {
          title
          status
          id
          publishDate
          localization {
            code
          }

          content {
            document(hydrateRelationships: true)
          }
          contentFull {
            document(hydrateRelationships: true)
          }
          featureImage {
            extension
            filesize
            height
            id
            width
          }

          writer
          profilePicture {
            width
            url
            id
            height
            filesize
            extension
          }
        }
      }
    }
  `);
  const { posts } = data?.cms;
  return posts;
};

export default usePosts;

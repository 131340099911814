import React, { useState } from "react";
import Layout from "@/components/Layout";
import { graphql, Link } from "gatsby";
import RecentPosts from "@/components/News/RecentPosts";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { KeystoneImage } from "@/components/gatsby-image-keystone";

import { PostType } from "@/types";
import usePosts from "@/hooks/usePosts";
import convertSlug from "@/utils/convertSlug";
import { useLocation } from "@reach/router";
import { FiLink } from "react-icons/fi";
import { CustomRenderer } from "@/utils/CustomRenderer";

// interface Props {
//   data: {
//     cms: {
//       post: PostType;
//     };
//   };
//   pageContext: any;
// }

const PostPage = ({ data }: any) => {
  const { post } = data?.cms;

  const { title, id, contentFull, featureImage, publishDate } = post;

  const posts = usePosts();

  const path = useLocation().href;

  const index = posts.findIndex((object: any) => {
    return object.id === id;
  });

  var [count, setCount] = useState(index);

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  // const findImage = data.cms.images.map((e: any) => {
  //   console.log("image:", e.image);
  // });

  return (
    <Layout title={title}>
      <div className="xl:container px-[20px] xl:px-0">
        <div className="mt-[2rem] lg:grid lg:grid-cols-[64%_36%]">
          {/* Article */}
          <div className="">
            <div>
              {featureImage && (
                <KeystoneImage
                  baseUrl="steppelink"
                  image={{
                    alt: featureImage.id,
                    width: 815,
                    height: featureImage.height * (815 / featureImage.width),
                    key: `${featureImage.id}.${featureImage.extension}`,
                  }}
                  layout="fullWidth"
                  alt={featureImage.id}
                />
              )}
            </div>

            {/* Post Details */}
            <div className="md:flex justify-between">
              {/* Date */}
              <div className="relative my-[1rem]">
                <span className="absolute top-[0%] h-[24px] -translate-y-[0%] border-l-[4px] border-black"></span>
                <div className="pl-[1rem] text-left text-[14px] font-[700] uppercase">
                  POSTED ON {moment(publishDate).format("LL")} IN BLOG
                </div>
              </div>

              <div
                className="relative group my-auto"
                onClick={handleButtonClick}
              >
                <button
                  aria-label="Copy link"
                  onClick={() => {
                    navigator.clipboard.writeText(path);
                  }}
                  className="active:translate-y-[1px] my-auto"
                >
                  <FiLink size={20} />
                </button>
                {isAlertVisible && (
                  <div className="absolute top-[100%] rounded-lg right-[50%] translate-x-[50%] bg-gray dark:bg-white px-[10px]">
                    <p className="dark:text-black">Copied!</p>
                  </div>
                )}
              </div>
            </div>

            {/* Title */}

            {/* Article */}
            <article className="prose lg:mt-[3rem] text-theme dark:text-white">
              <h1 className="my-[1rem] lg:text-[35px] text-theme dark:text-white">
                {title}
              </h1>
              <div>
                <CustomRenderer document={contentFull.document} />
              </div>
            </article>

            {/* Next & Previous post */}
            <div className="flex justify-between gap-[1rem] mt-[1rem] mb-[2rem]">
              <div className="mt-[1rem] border-t-[1px] border-lightGray text-right ">
                {count !== 0 && (
                  <Link
                    onClick={() => {
                      setCount(count - 1);
                    }}
                    aria-label={post.title}
                    to={`/blogs/${convertSlug(posts[count - 1].title)}`}
                    className={`font-[500] bg-black w-[145px] sm:w-[174px] py-[10px] pr-[1.5rem] pl-[0.5rem] hover:bg-theme text-white md:py-[0.5rem] flex justify-between duration-300 ease-in-out text-[12px] md:text-[16px]`}
                  >
                    <IoIosArrowBack className="my-auto mr-[1rem]" />
                    Previous post
                  </Link>
                )}
              </div>
              <div className="mt-[1rem] border-t-[1px] border-lightGray text-right ">
                {count !== posts.length - 1 && (
                  <Link
                    onClick={() => {
                      setCount(count + 1);
                    }}
                    to={`/blogs/${convertSlug(posts[count + 1].title)}`}
                    className="font-[500] w-[145px] sm:w-[174px] py-[10px] pl-[1rem] md:pl-[1.5rem] md:pr-[0.5rem] hover:bg-orangeDark bg-orange text-white md:py-[0.5rem] flex justify-between duration-300 ease-in-out text-[12px] md:text-[16px]"
                  >
                    Next post
                    <IoIosArrowForward className="my-auto ml-[1rem]" />
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="lg:pl-[6rem]">
            <p className="font-[600] mb-[1rem]">Suggested for you</p>
            <RecentPosts data={posts} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NewsById($id: ID) {
    cms {
      post(where: { id: $id }) {
        title
        id
        publishDate
        localization {
          code
        }
        contentFull {
          document(hydrateRelationships: true)
        }
        featureImage {
          extension
          filesize
          height
          id
          width
        }
      }
      images {
        image {
          extension
          filesize
          height
          id
          url
          width
        }
        title
      }
    }
  }
`;

export default PostPage;
